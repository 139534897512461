import { all, call, put, takeLatest } from 'redux-saga/effects';
import { unitySectionService } from '@tbx/experience-widgets-lib';

import * as actions from './actions';
import { types } from './constants';

function* fetchSectionWidgets(action) {
  const { accessToken, filters, sectionID } = action;

  try {
    const { result } = yield call(
      unitySectionService.getSectionComponents,
      accessToken,
      sectionID,
      filters,
    );

    if (!result || result.error) {
      throw new Error(result.error);
    }

    return yield all([put(actions.loadWidgetsSuccess(sectionID, result))]);
  } catch (e) {
    console.error(`SECTION_WIDGETS_FETCH_ERROR section:"${sectionID}" `, e);
    yield put(actions.loadWidgetsError(sectionID, e));
  }
}

function* saga() {
  yield takeLatest(types.WIDGETS_FETCH, fetchSectionWidgets);
}

export default saga;
