import { createSelector } from 'reselect';

const getAppReducer = (state) => state.containers.app;
const getSessionReducer = (state) => state.session;
const getRouterReducer = (state) => state.router;
const getProfileReducer = (state) => state.containers.profileManager;
const getAppSectionsReducer = (state) => state.containers.appSections;

export const selectAccessToken = createSelector(
  getSessionReducer,
  (reducer) => reducer.accessToken,
);

export const selectAppSettings = createSelector(
  getAppReducer,
  (reducer) => reducer.appSettings,
);

export const selectAuthenticated = createSelector(
  getSessionReducer,
  (reducer) => reducer.authenticated,
);

export const selectError = createSelector(getAppReducer, (reducer) => {
  return reducer.error;
});

export const selectIsFetching = createSelector(
  getAppReducer,
  (reducer) => reducer.isFetching,
);

export const selectLocation = createSelector(
  getRouterReducer,
  (reducer) => reducer.location,
);

export const selectToggleProfilesModal = createSelector(
  getAppReducer,
  (reducer) => reducer.toggleProfilesModal,
);

export const selectNotificationModal = createSelector(
  getAppReducer,
  (reducer) => reducer.notificationModal,
);

export const selectUnityAuthData = createSelector(
  getAppReducer,
  (reducer) => reducer.unityAuthData,
);

export const selectProfileCollection = createSelector(
  getProfileReducer,
  (reducer) => reducer.profileCollection,
);

export const selectToastData = createSelector(
  getAppReducer,
  (reducer) => reducer.toast,
);

export const selectPathList = createSelector(
  getAppSectionsReducer,
  (reducer) => reducer.pathList,
);

export const selectRatingImages = createSelector(
  getAppReducer,
  (reducer) => reducer.ratingImages,
);

export const selectAccountInformation = createSelector(
  getAppReducer,
  (reducer) => reducer.accountInfo,
);
