export const types = {
  APP_DEVICE_AUTHN: 'App/DEVICE_AUTHN',
  APP_DEVICE_AUTHN_FAILURE: 'App/DEVICE_AUTHN_FAILURE',
  APP_DEVICE_AUTHN_OTT: 'App/APP_DEVICE_AUTHN_OTT',
  APP_DEVICE_AUTHN_OTT_FAILURE: 'App/APP_DEVICE_AUTHN_OTT_FAILURE',
  APP_DEVICE_LOGGED_OUT: 'App/DEVICE_LOGGED_OUT',
  APP_DEVICE_LOGGED_OUT_SUCCESS: 'App/DEVICE_LOGGED_OUT_SUCCESS',
  APP_STARTUP: 'App/STARTUP',
  APP_STARTUP_SUCCESS: 'App/STARTUP_SUCCESS',
  APP_TOGGLE_PROFILES_MODAL: 'App/TOGGLE_PROFILES_MODAL',
  APP_NOTIFICATION_MODAL_CREATE: 'App/NOTIFICATION_MODAL_CREATE',
  APP_NOTIFICATION_MODAL_DELETE: 'App/NOTIFICATION_MODAL_DELETE',
  APP_TOKEN_FETCH: 'App/TOKEN_FETCH',
  APP_TOKEN_FETCH_FAILURE: 'App/TOKEN_FETCH_FAILURE',
  APP_TOKEN_FETCH_SUCCESS: 'App/TOKEN_FETCH_SUCCESS',
  APP_TOKEN_REFRESH: 'App/TOKEN_REFRESH',
  APP_TOKEN_REFRESH_FAILURE: 'App/TOKEN_REFRESH_FAILURE',
  APP_TOKEN_REFRESH_SUCCESS: 'App/TOKEN_REFRESH_SUCCESS',
  APP_TOAST_ADD: 'App/TOAST_ADD',
  APP_TOAST_DELETE: 'App/TOAST_DELETE',
  FIREBASE_SET_TOKEN: 'App/FIREBASE_SET_TOKEN',
  START_BACKGROUND_FETCH_PROFILES: 'App/START_BACKGROUND_FETCH_PROFILES',
  STOP_BACKGROUND_FETCH_PROFILES: 'App/STOP_BACKGROUND_FETCH_PROFILES',
  LISTEN_BACKGROUND_PROCESS: 'App/LISTEN_BACKGROUND_PROCESS',
  RATING_IMAGES_FETCH: 'App/RATING_IMAGES_FETCH',
  RATING_IMAGES_FETCH_SUCCESS: 'App/RATING_IMAGES_FETCH_SUCCESS',
  RATING_IMAGES_FETCH_FAILURE: 'App/RATING_IMAGES_FETCH_FAILURE',
  LOAD_ACCOUNT_INFORMATION: 'App/LOAD_ACCOUNT_INFORMATION',
  LOAD_ACCOUNT_INFORMATION_SUCCESS: 'App/LOAD_ACCOUNT_INFORMATION_SUCCESS',
  LOAD_ACCOUNT_INFORMATION_FAILURE: 'App/LOAD_ACCOUNT_INFORMATION_FAILURE',
};

export const UNITY_ERROR_CODES = {
  DEVICE_LOGGED_OUT: 'CP-006',
  DEVICE_NOT_FOUND: 'CP-004',
};

export const TOAST_TYPES = {
  WARNING: 'WARNING',
  ERROR: 'ERROR',
  INFO: 'INFO',
};
