import React from 'react';
import classNames from '../../utils/classnameUtils';

import Backdrop from '../Backdrop';

import './styles.scss';

function Modal({
  backgroundImageURL,
  children,
  modalClosed,
  show,
  classNameCustom = {},
}) {
  let backgroundStyle;

  if (backgroundImageURL) {
    backgroundStyle = {
      backgroundSize: 'cover',
      backgroundImage: `url(${backgroundImageURL})`,
    };
  }

  React.useEffect(() => {
    if (show) {
      document.body.style['overflow-y'] = 'hidden';
    } else {
      document.body.style['overflow-y'] = 'auto';
    }
  }, [show]);

  return (
    <React.Fragment>
      <Backdrop show={show} toggleBackdrop={modalClosed} />
      <div
        style={backgroundStyle}
        className={classNames(
          'tbxContentModal',
          show ? 'show' : 'hide',
          classNameCustom || '',
        )}
      >
        {children}
      </div>
    </React.Fragment>
  );
}

export default Modal;
