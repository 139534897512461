import { unityUserService } from '@tbx/experience-widgets-lib';
import { call, takeLatest } from 'redux-saga/effects';
import { types } from './constants';
import { replaceUrlParameter } from '../../utils/pathRoutesUtils';
import useClientConfigs from '../../customHooks/useClientConfigs';
/**
 * Fetch redirect to account information
 *
 * @param {*} action
 */
function* redirectToAccountInfo(action) {
  const { accessToken, hasDirectAccessToAddOns } = action;
  const { useDirectAccessToAddOns } = useClientConfigs();

  try {
    const { result: accountInfo } = yield call(
      unityUserService.getUserAccountInformation,
      accessToken,
    );

    if (!accountInfo || accountInfo.error) {
      throw new Error(accountInfo);
    }
    if (accountInfo.ok) {
      const detailsLink =
        hasDirectAccessToAddOns && useDirectAccessToAddOns
          ? `${replaceUrlParameter(
              accountInfo.detailsLink,
              'return_url',
              encodeURIComponent(window.location.href),
            )}&hasDirectAccessToAddOns=${hasDirectAccessToAddOns}`
          : accountInfo.detailsLink;
      window.location.href = detailsLink;
    } else {
      throw new Error('Error getting account information');
    }
  } catch (e) {
    console.error('Error getting account information: ', e);
  }
}

function* saga() {
  yield takeLatest(types.ACCOUNT_INFORMATION, redirectToAccountInfo);
}

export default saga;
