import { unitySectionService } from '@tbx/experience-widgets-lib';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';

import { validateUrlRedirect } from '../../../utils/ContentUtils';
import { convertPath } from '../../../utils/pathRoutesUtils';
import { fetchCurrentContentPositionByProfile } from '../../ProfileManager/actions';
import { types as profileTypes } from '../../ProfileManager/constants';
import { selectCurrentContentPosition } from '../../ProfileManager/selectors';
import { getContentsUrlById } from '../ContentOverViewSection/actions';
import { types as ContentOverviewTypes } from '../ContentOverViewSection/constants';
import { selectContentsUrlByID } from '../ContentOverViewSection/selectors';
import * as actions from './actions';
import { types } from './constants';
import { selectAccessToken, selectAuthenticated } from './selectors';

function* fetchSectionWidgets(action) {
  const { accessToken, filters, sectionID } = action;

  try {
    const { result } = yield call(
      unitySectionService.getSectionComponents,
      accessToken,
      sectionID,
      filters,
    );

    if (!result || result.error) {
      throw new Error(result.error);
    }

    return yield all([put(actions.loadWidgetsSuccess(result))]);
  } catch (e) {
    console.error('SECTION_WIDGETS_FETCH_ERROR: ', e);
    yield put(actions.loadWidgetsError(e));
  }
}

function* getEpisodeIdBySerie(action) {
  const { contentID, router, pathPlayer } = action;
  const accessToken = yield select((state) => selectAccessToken(state));
  const authenticated = yield select((state) => selectAuthenticated(state));

  try {
    yield put(fetchCurrentContentPositionByProfile(accessToken, contentID));
    yield take(profileTypes.CURRENT_CONTENT_POSITION_BY_PROFILE_FETCH_SUCCESS);

    const currentContentPosition = yield select((state) =>
      selectCurrentContentPosition(state),
    );
    const { currentContent } = currentContentPosition || {};
    const { id: episodeID } = currentContent || {};
    const hasRedirect = validateUrlRedirect(currentContentPosition);

    if (!hasRedirect) router.push(convertPath(pathPlayer, contentID));
    else {
      if (hasRedirect && authenticated) {
        yield put(getContentsUrlById(accessToken, episodeID));
        yield take(ContentOverviewTypes.CONTENTS_URL_ID_FETCH_SUCCESS);

        const redirectUrl = yield select((state) =>
          selectContentsUrlByID(state),
        );
        window.open(redirectUrl, '_blank');
      }
    }
  } catch (e) {
    console.error('GET_EPISODE_BY_SERIE_ERROR: ', e);
    yield put(actions.loadWidgetsError(e));
  }
}

function* saga() {
  yield takeLatest(types.WIDGETS_FETCH, fetchSectionWidgets);
  yield takeLatest(types.GET_EPISODE_BY_SERIE, getEpisodeIdBySerie);
}

export default saga;
