import { put, race, take, takeEvery } from 'redux-saga/effects';

import { deviceLogout } from './actions';
import { types, UNITY_ERROR_CODES } from './constants';

const ignoreActionTypes = [types.APP_TOKEN_REFRESH];

function monitorableAction(action) {
  return (
    action.type.endsWith('_FETCH') &&
    ignoreActionTypes.every((fragment) => !action.type.includes(fragment))
  );
}

/* function identifyAction(action) {
  return action.type.split('_').slice(0, -1).join('_')
} */

function getSuccessType(action) {
  return `${action.type}_SUCCESS`;
}

function getFailType(action) {
  return `${action.type}_FAILURE`;
}

/**
 * Monitor device status on requests (actions with _FETCH).
 *
 * @param {*} monitoredAction
 */
function* deviceMonitor(monitoredAction) {
  console.log('>>> Started device monitoring', monitoredAction.type);
  const { fail } = yield race({
    success: take(getSuccessType(monitoredAction)),
    fail: take(getFailType(monitoredAction)),
  });

  if (fail && fail.error) {
    const { error } = fail;
    if (
      error.code === UNITY_ERROR_CODES.DEVICE_LOGGED_OUT ||
      error.code === UNITY_ERROR_CODES.DEVICE_NOT_FOUND
    ) {
      console.log(
        `>> Detected device logged out error. [CODE:${fail.error.code}]`,
      );
      yield put(deviceLogout());
    }
  }

  console.log('>>> Monitoring', monitoredAction.type, 'finished');
}

function* monitorSaga() {
  yield takeEvery(monitorableAction, deviceMonitor);
}

export default monitorSaga;
