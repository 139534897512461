import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MAX_CHARS_CODE } from '../../containers/sections/ActivateCodeSection/constants';
import { isDesktop } from 'react-device-detect';
import './styles.scss';
import { Imgcomponent, PinCodeInput } from '@tbx/experience-widgets-lib';

function ActivateCode({
  validatePinCodeStatus,
  handlePinCodeChange,
  handleConfirmCode,
  handleBackToSite,
  pinCodeValue,
  showMessage,
  activateButton = false,
  cleanUpHanlderClosure,
  isDisabled,
  isFetching,
}) {
  const { t } = useTranslation();

  return (
    <div
      className={`activateCodeContainer ${isFetching ? 'disabledForm' : ''}`}
    >
      {!isDesktop && (
        <Imgcomponent src="/images/logo.png" className={'activateCodeImg'} />
      )}
      {validatePinCodeStatus && (
        <React.Fragment>
          <i className="bi bi-check-circle activateCodeCheckCircle" />
          <div className="activateCodeSuccess">
            {t('¡Código activado correctamente!')}
          </div>
        </React.Fragment>
      )}
      {!validatePinCodeStatus && (
        <React.Fragment>
          <label className="activateCodeTitle">
            {t('Activate your device')}
          </label>
          <p className="activateCodeSubtitle">
            {t('Enter screen validation code')}
          </p>
          <PinCodeInput
            handlePinCodeChange={handlePinCodeChange}
            numberOfFields={MAX_CHARS_CODE.CUSTOM}
            classNameCustom={'activateCode'}
            cleanUpHanlderClosure={cleanUpHanlderClosure}
            isDisabled={isDisabled}
          />
          <div className="activateCodeAlert">
            <Alert show={showMessage} variant={'danger'}>
              <p>{!validatePinCodeStatus && t('Activate Code Error')}</p>
            </Alert>
          </div>
          {validatePinCodeStatus ? (
            <Button
              className={'backToSite_button'}
              onClick={() => handleBackToSite()}
            >
              {t('Back to site')}
            </Button>
          ) : (
            <div className="buttonOptionsContainer">
              {/* isDesktop && <Button 
            className={"cancel_button"}
            onClick={()=> handleBackToSite()}
            > 
            {t('Cancel')}
        </Button> */}
              {activateButton && (
                <Button
                  className={'activate_button'}
                  onClick={() => handleConfirmCode()}
                  disabled={!(pinCodeValue?.length === MAX_CHARS_CODE.CUSTOM)}
                >
                  {t('Activate')}
                </Button>
              )}
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
}

export default React.memo(ActivateCode);
