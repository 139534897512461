import { types } from './constants';

export const fetchEPGDataByIds = (unityToken, epgIds, dateFrom, dateTo) => ({
  type: types.FETCH_EPG_DATA_BY_IDS,
  unityToken,
  epgID: epgIds,
  from: dateFrom,
  to: dateTo,
});

export const setEpgDataByIdsSuccess = (result) => ({
  type: types.EPG_DATA_BY_IDS_SUCCESS,
  result,
});

export const setEpgDataByIdsError = (error) => ({
  type: types.EPG_DATA_BY_IDS_ERROR,
  error,
});

export const loadWidget = (accessToken, contentID) => ({
  type: types.WIDGET_FETCH,
  accessToken,
  contentID,
});

export const loadWidgetError = (error) => ({
  type: types.WIDGET_FETCH_FAILURE,
  error,
});

export const loadWidgetSuccess = (content) => ({
  type: types.WIDGET_FETCH_SUCCESS,
  content,
});

export const isPlayerEpgSection = (isPlayerEpgSection) => ({
  type: types.IS_PLAYEREPG_SECTION,
  isPlayerEpgSection,
});

export const changeWidget = (content) => ({
  type: types.WIDGET_CHANGE,
  content,
});

export const loadWidgetEpgSuccess = (contentEpg) => ({
  type: types.WIDGET_FETCH_SUCCESS,
  contentEpg,
});

export const selectContent = (content, epgList) => ({
  type: types.CONTENT_SELECTED,
  content,
  epgList,
});

export const clearStates = () => ({
  type: types.CLEAR_STATES,
});

export const clearErrors = () => ({
  type: types.CLEAR_ERRORS,
});
