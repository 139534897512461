import { types } from './constants';

export const loadWidgets = (accessToken, sectionID, filters = {}) => ({
  type: types.WIDGETS_FETCH,
  accessToken,
  filters,
  sectionID,
});

export const loadWidgetsError = (sectionID, error) => ({
  type: types.WIDGETS_FETCH_FAILURE,
  error,
  sectionID,
});

export const loadWidgetsSuccess = (sectionID, widgetsCollection) => ({
  type: types.WIDGETS_FETCH_SUCCESS,
  sectionID,
  widgetsCollection,
});

export const selectContent = (sectionID, content) => ({
  type: types.CONTENT_SELECTED,
  content,
  sectionID,
});

export const toggleModal = (sectionID, show) => ({
  type: types.TOGGLE_MODAL,
  sectionID,
  show,
});

export const loadMoreWidgets = (sectionID) => ({
  type: types.WIDGETS_LOAD_MORE,
  sectionID,
});
