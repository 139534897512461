import { call, put, takeLatest } from 'redux-saga/effects';
import { unitySectionService } from '@tbx/experience-widgets-lib';

import * as actions from './actions';
import { types } from './constants';

function* fetchSections(action) {
  const { accessToken, unityAuthData } = action;

  try {
    const { result } = yield call(
      unitySectionService.getSections,
      accessToken,
      { pageSize: 400 },
    );

    if (!result || result.error) {
      throw new Error(result.error);
    }

    const pathCountry = `/${
      window.__TBX_ENV__.TBX_FORCE_COUNTRY?.toLowerCase() ||
      unityAuthData?.ipUserCountry?.toLowerCase()
    }`;

    const pathsSectionCollectionResult = result?.result?.reduce(
      (previusValue, { id, url }) => {
        previusValue[id] = pathCountry + url;
        return previusValue;
      },
      {},
    );

    yield put(
      actions.pathRoutesAddSectionCollection(pathsSectionCollectionResult),
    );
    yield put(actions.pathCountryAdd(pathCountry));
    yield put(actions.loadAppSectionsSuccess(result));
  } catch (e) {
    console.error('UNITY_SECTIONS_FETCH_ERROR: ', e);
    yield put(actions.loadAppSectionsError(e));
  }
}

function* saga() {
  yield takeLatest(types.ROUTER_SECTIONS_FETCH, fetchSections);
}

export default saga;
