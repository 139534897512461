import React from 'react';
import useRouter from '../../customHooks/useRouter';

// create contexts
const PlayerCallbackState = React.createContext();

// context consumer hook
const usePlayerCallbackState = () => {
  // get the context
  const context = React.useContext(PlayerCallbackState);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('usePlayerCallbackState was used outside of its Provider');
  }

  return context;
};

const getPlayer = (playerID) => window.videojs.getPlayer(playerID);

const clearAllPlayerVideoJsHandler = (playerID) => {
  const allPlayersVideoJs = window.videojs.getAllPlayers();
  allPlayersVideoJs.forEach((playerVideoJs) => {
    if (playerVideoJs.id_ !== playerID && playerVideoJs) {
      if (playerVideoJs.isInPictureInPicture())
        playerVideoJs?.exitPictureInPicture();
      console.log(
        '>> clearAllPlayerVideoJsHandler CLEANUP PLAYER  -return: ',
        playerVideoJs,
      );
      playerVideoJs.dispose();
    }
  });
};

const PlayerCallbackContextProvider = ({ children }) => {
  const { pathname } = useRouter();
  // the value that will be given to the context
  const [playerCallback, setPlayerCallback] = React.useState(null);
  const [pictureInPicture, setPictureInPicture] = React.useState(null);
  let setPictureInPictureHandlerState = null;

  const leavepictureinpicture = () => {
    const bool = false;
    setPictureInPicture(bool);
    setPictureInPictureHandlerState && setPictureInPictureHandlerState(bool);
  };
  const enterpictureinpicture = () => {
    const bool = true;
    setPictureInPicture(bool);
    setPictureInPictureHandlerState && setPictureInPictureHandlerState(bool);
  };

  React.useEffect(() => {
    const playerVideoJs = playerCallback?.playerVideoJs();
    if (playerVideoJs) {
      playerVideoJs.on('leavepictureinpicture', leavepictureinpicture);
      playerVideoJs.on('enterpictureinpicture', enterpictureinpicture);
      return () => {
        playerVideoJs.off('leavepictureinpicture', leavepictureinpicture);
        playerVideoJs.off('enterpictureinpicture', enterpictureinpicture);
      };
    }
  }, [playerCallback?.playerVideoJs]);

  React.useEffect(() => {
    // cuando se cambia de seccion y no esta activo el pictureInPicture se hace dispose del player.
    if (
      playerCallback &&
      !pictureInPicture &&
      playerCallback?.pathname !== pathname
    ) {
      clearPlayerCallbackHandler();
    }
  }, [pathname]);

  // sign out the user, memoized
  const setPlayerCallbackHandler = React.useCallback(
    async (props) => {
      try {
        const { playerID, nodeElement, pathname } = await props();
        if (playerID && nodeElement && pathname) {
          clearAllPlayerVideoJsHandler(playerID);
          const playerVideoJsResult = getPlayer(playerID);
          const objectHelp = playerCallback || {};
          setPlayerCallback({
            ...objectHelp,
            playerID,
            nodeElement,
            pathname,
            contentType: playerVideoJsResult.tbx.content.contentType,
            playerVideoJs: () => getPlayer(playerID),
          });
        } else {
          clearAllPlayerVideoJsHandler();
        }
      } catch (e) {
        console.error('>>>>>> setPlayerCallbackHandler: ', e);
      }
    },
    [playerCallback],
  );

  // sign out the user, memoized
  const setPictureInPicturePlayerCallbackHandler = React.useCallback(
    (setPictureInPictureHandler) => {
      try {
        if (typeof setPictureInPictureHandler !== 'function')
          throw new Error(
            'Invalid props setPictureInPicturePlayerCallbackHandler',
          );
        setPictureInPictureHandlerState = setPictureInPictureHandler;
      } catch (e) {
        console.error('>>>>>> setPictureInPicturePlayerCallbackHandler: ', e);
      }
    },
    [playerCallback],
  );

  const clearPlayerCallbackHandler = React.useCallback(() => {
    try {
      if (playerCallback?.playerID) {
        clearAllPlayerVideoJsHandler(playerCallback.playerID);
        const playerCallbackVideoJs = playerCallback.playerVideoJs();
        if (playerCallbackVideoJs) {
          if (playerCallbackVideoJs.isInPictureInPicture())
            playerCallbackVideoJs?.exitPictureInPicture();
          setPictureInPicture(null);
          setPictureInPictureHandlerState = null;
          console.log(
            '>> clearPlayerCallbackHandler CLEANUP PLAYER  -return: ',
            playerCallbackVideoJs,
          );
          playerCallbackVideoJs.dispose();
        }
      }
      setPlayerCallback(null);
    } catch (e) {
      console.error('>>>>>> clearPlayerCallbackHandler: ', e);
    }
  }, [playerCallback]);

  // memoize the full context value
  const contextValue = React.useMemo(
    () => ({
      state: playerCallback,
      pictureInPictureState: pictureInPicture,
      setPictureInPicturePlayerCallbackHandler,
      setPlayerCallback: setPlayerCallbackHandler,
      clearPlayerCallback: clearPlayerCallbackHandler,
    }),
    [
      playerCallback,
      pictureInPicture,
      setPictureInPicturePlayerCallbackHandler,
      setPlayerCallbackHandler,
      clearPlayerCallbackHandler,
    ],
  );

  return (
    // the Provider gives access to the context to its children
    <PlayerCallbackState.Provider value={contextValue}>
      {children}
    </PlayerCallbackState.Provider>
  );
};

export { usePlayerCallbackState };

export default PlayerCallbackContextProvider;
