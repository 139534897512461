import { SECTIONS_WITHOUT_ONCLICK } from '../../constants/htmlComponentHub';

const slideToSide = ({ scroll, arrow, slider }) => {
  arrow.style.display = 'flex';
  slider.scroll({
    left: scroll,
    behavior: 'smooth',
  });
};

const isArrow = (arrow) => {
  return (
    arrow?.classList.contains('swiper-button-prev') ||
    arrow?.classList.contains('swiper-button-next')
  );
};

const handleSlider = ({ event, currentScroll, setScroll }) => {
  const htmlElement = event?.target;

  if (isArrow(htmlElement)) {
    const sliderId = htmlElement?.dataset?.slider;
    const slider = document.querySelector(`.${sliderId}-slider`);

    const rightArrow = document.querySelector(
      `.swiper-button-next[data-slider="${sliderId}"]`,
    );

    const leftArrow = document.querySelector(
      `.swiper-button-prev[data-slider="${sliderId}"]`,
    );

    let scroll = 0;

    if (scroll === 0) {
      scroll = currentScroll + slider.offsetWidth;
      setScroll(scroll);
      slideToSide({
        slider,
        scroll,
        arrow: rightArrow,
      });
    }

    rightArrow.addEventListener('click', () => {
      scroll = currentScroll + slider.offsetWidth;
      setScroll(scroll);
      slideToSide({
        slider,
        scroll,
        arrow: rightArrow,
      });
    });

    leftArrow.addEventListener('click', () => {
      scroll = currentScroll - slider.offsetWidth;
      setScroll(scroll);
      slideToSide({
        slider,
        scroll,
        arrow: leftArrow,
      });
    });

    leftArrow.style.display = scroll > 0 ? 'flex' : 'none';
    rightArrow.style.display = scroll < slider.offsetWidth ? 'flex' : 'none';
  }
};

const handleRedirect = (event, history) => {
  const pathsWithoutOnClick = SECTIONS_WITHOUT_ONCLICK.find((p) =>
    window.location.pathname.includes(p),
  );

  if (!pathsWithoutOnClick) {
    event.preventDefault();
    let htmlElement = event.target;

    if (
      !htmlElement.classList.contains('swiper-button-prev') &&
      !htmlElement.classList.contains('swiper-button-next')
    ) {
      while (!htmlElement.getAttribute('href')) {
        htmlElement = htmlElement.parentElement;
      }

      if (htmlElement.getAttribute('href')) {
        const href = htmlElement.getAttribute('href');
        if (href !== '#') history.push(href);
      }
    }
  }
};

export const handleClick = ({ event, history, currentScroll, setScroll }) => {
  handleSlider({ event, currentScroll, setScroll });
  handleRedirect(event, history);
};

export const handleMouseEnter = ({ currentScroll, setScroll }) => {
  handleSlider({ currentScroll, setScroll });
};
