import { googleAnalyticEvents } from '@tbx/experience-widgets-lib';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebase';

/**
 * Add google Analytic events
 * @param {*} values
 * a
 */
export const setGoogleAnalyticEvents = (values) => {
  const { title: page_title } = values;
  console.log('VALORES', page_title);
  const pageViewLog = googleAnalyticEvents.setPageViewEvent({ page_title });

  if (Object.keys(values).length > 0) {
    logEvent(analytics, pageViewLog.eventName, { ...pageViewLog.attributes });
  }
};
