import React from 'react';
import { CONTENT_TYPES } from '../constants/contentTypes';
import {
  DEFAULT_VOLUME,
  PLAYER_IN_LOCALSTORAGE,
  PLAYER_PREFERENCES,
} from '../constants/player';

const volumeAsPercentage = (currentValue) => {
  return Math.round(currentValue * 100);
};

const updateLocalStorage = (currentPlayer, currentValue) => {
  if (currentPlayer?.volume() || currentValue === 0) {
    PLAYER_PREFERENCES.volumeLevel = currentValue;
    localStorage.setItem(
      PLAYER_IN_LOCALSTORAGE,
      JSON.stringify(PLAYER_PREFERENCES),
    );
  }
};

const drawVolumeLevel = (currentPlayer, currentValue) => {
  const volumeLevelDiv =
    currentPlayer?.controlBar?.volumePanel?.volumeControl?.volumeBar
      ?.volumeLevel;

  if (volumeLevelDiv) {
    volumeLevelDiv.el_.style.width = currentValue + '%';
  }
};

const updateARIAAttributes = (currentPlayer, currentValue) => {
  if (!currentPlayer || currentPlayer.player_ === null) return;

  currentPlayer && currentPlayer?.volume(currentValue);
  const ariaValue = currentPlayer?.muted()
    ? 0
    : volumeAsPercentage(currentValue);
  currentPlayer?.el_.setAttribute('aria-valuenow', ariaValue);
  currentPlayer?.el_.setAttribute('aria-valuetext', ariaValue + '%');

  updateLocalStorage(currentPlayer, currentValue);
  drawVolumeLevel(currentPlayer, ariaValue);
};

export function usePlayerPreferencesUpdate(currentPlayer) {
  React.useEffect(() => {
    currentPlayer?.on('volumechange', () =>
      updateARIAAttributes(
        currentPlayer,
        currentPlayer?.muted() ? 0 : currentPlayer?.volume(),
      ),
    );
  }, [currentPlayer?.volume()]);
}

export function usePlayerPreferencesInit(
  currentPlayer,
  isEpgSection = false,
  selectedContent,
) {
  const playerPreferences = JSON.parse(
    localStorage.getItem(PLAYER_IN_LOCALSTORAGE),
  );

  React.useEffect(() => {
    if (!currentPlayer) return;

    if (playerPreferences && !(Object.keys(playerPreferences).length === 0)) {
      updateARIAAttributes(currentPlayer, playerPreferences.volumeLevel);

      if (playerPreferences.volumeLevel === 0) {
        currentPlayer?.muted(true);
        currentPlayer && currentPlayer?.volume(DEFAULT_VOLUME);
      }
    } else {
      if (
        isEpgSection ||
        selectedContent?.contentType === CONTENT_TYPES.BROADCAST
      ) {
        currentPlayer?.muted(true);
        currentPlayer && currentPlayer?.volume(DEFAULT_VOLUME);
      } else {
        updateARIAAttributes(currentPlayer, DEFAULT_VOLUME);
      }
    }
  }, [currentPlayer, selectedContent]);
}
