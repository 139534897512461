import { IMAGE_MODE } from '../constants/contentTypes';

/**
 * normalizeImageMode
 * @param {String} rowType
 *
 * @return {String}
 */
export const normalizeImageMode = (rowType) => {
  switch (rowType) {
    case 'poster':
      return IMAGE_MODE.POSTER;
    case 'simple':
      return IMAGE_MODE.THUMB;
    case 'simpleLarge':
      return IMAGE_MODE.SIMPLE_LARGE;
    default:
      return IMAGE_MODE.THUMB;
  }
};

/**
 * Return image from imageArray based on mode
 */
export const getContentImageByMode = (imagesArray, mode) => {
  mode = IMAGE_MODE[mode];
  let image = null;
  if (!Array.isArray(imagesArray) || typeof mode !== 'string') return undefined;
  for (let i = 0; i < imagesArray.length; i++) {
    if (imagesArray[i].type === mode) {
      image = imagesArray[i];
      break;
    }
  }
  return image;
};
