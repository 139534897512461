import { types, PATHS_CONST, PATHS_CONST_WITHOUT_COUNTRY } from './constants';
import {
  createReducerWithSection,
  INITIAL_STATE as SECTION_INITIAL_STATE,
} from '../sections/Section/reducer';

const INITIAL_STATE = {
  error: {
    code: null,
    details: null,
    message: null,
  },
  isFetching: true,
  sectionCollection: [],
  sectionStates: new Map(),
  pathList: {
    ...PATHS_CONST,
    ...PATHS_CONST_WITHOUT_COUNTRY,
  },
  pathCountry: '/',
};

const reducer = (state = INITIAL_STATE, action) => {
  // INFO: Redirige las las actions de secciones dinamicas para update del state correspondiente a una sectionID.
  if (action.type.startsWith('@@section/')) {
    const currentReducer = createReducerWithSection(action.sectionID);
    const newState = currentReducer(
      state.sectionStates.get(action.sectionID),
      action,
    );

    return {
      ...state,
      sectionStates: state.sectionStates.set(action.sectionID, newState),
    };
  }
  switch (action.type) {
    case types.ROUTER_SECTIONS_FETCH:
      return {
        ...state,
        error: {
          ...INITIAL_STATE.error,
        },
        isFetching: true,
      };
    case types.ROUTER_SECTIONS_FETCH_FAILURE:
      return {
        ...state,
        error: {
          ...action.error,
        },
        isFetching: false,
      };
    case types.ROUTER_SECTIONS_ADD_PATH_ROUTES:
      return {
        ...state,
        pathList: {
          ...state.pathList,
          ...action.pathList,
        },
      };
    case types.ROUTER_SECTIONS_ADD_PATH_COUNTRY:
      return {
        ...state,
        pathCountry: action.pathCountry,
      };
    case types.ROUTER_SECTIONS_ADD_PATH_ROUTES_SECTION_COLLECTION:
      return {
        ...state,
        pathList: {
          ...state.pathList,
          ...action.pathList,
        },
      };
    case types.ROUTER_SECTIONS_FETCH_SUCCESS:
      const { sectionsData } = action;
      const map = new Map();

      if (Array.isArray(sectionsData.result)) {
        sectionsData.result.forEach((item) =>
          map.set(item.id, SECTION_INITIAL_STATE),
        );
      }

      return {
        ...state,
        isFetching: false,
        sectionCollection: sectionsData.result,
        sectionStates: map,
      };
    default:
      return state;
  }
};

export default reducer;
