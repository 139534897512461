import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastDelete } from '../../containers/App/actions';
import { selectToastData } from '../../containers/App/selectors';
import './styles.scss';

import ToastReact from 'react-bootstrap/Toast';
import { useTranslation } from 'react-i18next';
import { TOAST_TYPES } from '../../containers/App/constants';
import { selectSectionCollection } from '../../containers/AppSectionRouter/selectors';

function Toast() {
  // #region ////// SELECTORS ///////
  const toastData = useSelector((state) => selectToastData(state));
  // #endregion

  return (
    <React.Fragment>
      {toastData.length > 0 && (
        <div
          className="tbxToastContainer"
          style={{
            position: 'fixed',
            top: '80px',
            right: '48px',
            left: 'auto',
          }}
        >
          {toastData.map((data) => (
            <ToastComponent data={data} key={'ToastComponent_' + data.key} />
          ))}
        </div>
      )}
    </React.Fragment>
  );
}

const ToastComponent = ({ data }) => {
  const {
    key,
    message,
    temporary,
    time,
    title,
    toastType,
    error,
    link,
    image,
  } = data;
  const dispatch = useDispatch();
  const [show, setShow] = React.useState(true);
  const { t } = useTranslation();

  const sections = useSelector((state) => selectSectionCollection(state));
  const withImage = image !== '';

  const toggleShow = () => {
    setShow(false);
    dispatch(toastDelete(key));
  };

  const icono = {
    [TOAST_TYPES.WARNING]: (
      // eslint-disable-next-line react/no-unknown-property
      <i className="bi bi-exclamation-triangle-fill" fill={'red'}></i>
    ),
    [TOAST_TYPES.ERROR]: (
      // eslint-disable-next-line react/no-unknown-property
      <i className="bi bi-exclamation-triangle-fill" fill={'red'}></i>
    ),
    [TOAST_TYPES.INFO]: (
      // eslint-disable-next-line react/no-unknown-property
      <i className="bi bi-info-circle-fill" fill={'yellow'}></i>
    ),
  };

  const extractSectionId = () => {
    const idMatch = link.match(/\/section\/([^/?]+)/);
    // Check if a match is found and get the section ID
    const id = idMatch ? idMatch[1] : '';
    return id;
  };
  const handleOnClick = () => {
    if (link.includes('section')) {
      const sectionId = extractSectionId();
      const url = sections.filter((s) => s.id === sectionId)[0]?.url;
      const countryCode = window.location.pathname.split('/')[1];
      const newUrl = `${window.location.protocol}//${window.location.host}/${countryCode}${url}`;
      window.location.href = newUrl;
    } else if (link.includes('content')) {
      window.location.href = link;
    }
    toggleShow();
  };

  return (
    <ToastReact
      className={'tbxToastComponent'}
      show={show}
      onClose={toggleShow}
      delay={time}
      autohide={temporary}
      animation={false}
      onClick={handleOnClick}
    >
      <ToastReact.Header className={'tbxToastHeader'}>
        <div className={'tbxToastIcon'}>{icono[toastType]}</div>
        <strong className={'tbxToastTitle mr-auto'}>{t(title)}</strong>
      </ToastReact.Header>
      <ToastReact.Body
        className={withImage ? 'tbxToastBodyImg' : 'tbxToastBody'}
      >
        <text className={'tbxToastMessage'}>{t(message)}</text>
        {!!error?.code && (
          <div className={'tbxToastCode'}>{'Code error: ' + error.code}</div>
        )}
        {withImage && (
          <img src={image} alt="Notification" width="70px" height="70px" />
        )}
      </ToastReact.Body>
    </ToastReact>
  );
};

export default Toast;
