import { unityContentsService } from '@tbx/experience-widgets-lib';

export const types = {
  WIDGET_FETCH: 'ContentOverViewSection/WIDGET_FETCH',
  WIDGET_FETCH_FAILURE: 'ContentOverViewSection/WIDGET_FETCH_FAILURE',
  WIDGET_FETCH_SUCCESS: 'ContentOverViewSection/WIDGET_FETCH_SUCCESS',
  CLEAR_STATES: 'ContentOverViewSection/CLEAR_STATES',
  RELATED_WIDGET_FETCH_SUCCESS:
    'ContentOverViewSection/RELATED_WIDGET_FETCH_SUCCESS',
  EPISODES_WIDGET_FETCH_SUCCESS:
    'ContentOverViewSection/EPISODES_WIDGET_FETCH_SUCCESS',
  EPISODE_CURRENT_CONTENT_PROFILE_FETCH:
    'ContentOverViewSection/EPISODE_CURRENT_CONTENT_PROFILE_FETCH',
  EPISODE_CURRENT_CONTENT_PROFILE_FETCH_SUCCESS:
    'ContentOverViewSection/EPISODE_CURRENT_CONTENT_PROFILE_FETCH_SUCCESS',
  EPISODE_CURRENT_CONTENT_PROFILE_FETCH_FAILURE:
    'ContentOverViewSection/EPISODE_CURRENT_CONTENT_PROFILE_FETCH_FAILURE',
  CONTENTS_URL_ID_FETCH: 'ContentOverViewSection/CONTENTS_URL_ID_FETCH',
  CONTENTS_URL_ID_FETCH_FAILURE:
    'ContentOverViewSection/CONTENTS_URL_ID_FETCH_FAILURE',
  CONTENTS_URL_ID_FETCH_SUCCESS:
    'ContentOverViewSection/CONTENTS_URL_ID_FETCH_SUCCESS',
};

export const CONTENT_TYPES = {
  MOVIE: {
    SERVICE: unityContentsService.getContentsRelated,
    LOAD_ACTION: 'loadWidgetRelatedSuccess',
  },

  SERIE: {
    SERVICE: unityContentsService.getContentsEpisodes,
    LOAD_ACTION: 'loadWidgetEpisodesSuccess',
  },
};

export const TOAST_TEXT = {
  TITLE: 'Content',
  NOT_ENTITLEMENTS: 'Content not available.',
  'SE-003': 'Content not available.',
  DEFAULT: 'We are very sorry. There was a problem trying to open content.',
};

export const TITLES = [
  'Available until',
  'Play in',
  'Play now',
  'My list',
  'Genders',
  'Director',
  'Cast',
  'Continue watching',
  'Add favorites',
  'Remove favorites',
  'Episode',
  'Episodes',
  'Season',
  'Similar titles',
  'Content classification',
  'Content not available',
  'Buy subscription',
];

export const SHOW_RATING_IMAGE = false;
