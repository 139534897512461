import { Imgcomponent } from '@tbx/experience-widgets-lib';
import React from 'react';
import FadeIn from 'react-fade-in';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import classNames from '../../../utils/classnameUtils';
import { selectPathList, selectPathCountry } from '../selectors';
import './styles.scss';

const NavBarLink = ({ section, index, urlImage }) => {
  const PATHS = useSelector((state) => selectPathList(state));
  const pathCountry = useSelector((state) => selectPathCountry(state));
  const [isImg, setIsImg] = React.useState(false);

  React.useEffect(() => {
    if (urlImage) setIsImg(true);
  }, [urlImage]);

  return (
    <FadeIn
      className={'navLinkContainer'}
      delay={index * 110}
      key={`navLinkTo-${section.id}`}
    >
      <NavLink
        activeClassName="active-link"
        className={classNames(
          'navigation__container-link pseudo-link',
          'sectionId_' + section.id,
          'isImg_' + isImg,
        )}
        exact={section.isHome ? PATHS.home : false}
        to={section.isHome ? PATHS.home : pathCountry + section.url}
      >
        {isImg ? (
          <Imgcomponent
            src={urlImage}
            loading={'eager'}
            size={'THUMB_SECTION'}
            crop={'resizeScaleAndCrop'}
            setimagebool={(bool) => isImg !== bool && setIsImg(bool)}
          />
        ) : (
          section.name
        )}
      </NavLink>
    </FadeIn>
  );
};

export default NavBarLink;
