import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const IFrameComponent = ({ source }) => (
  <div className="iframe-container">
    <iframe
      title="gamecast"
      src={`${source}`}
      width="100%"
      height="700"
      scrolling="auto"
    ></iframe>
  </div>
);

IFrameComponent.propTypes = {
  source: PropTypes.string.isRequired,
};

export default IFrameComponent;
