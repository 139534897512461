import { unityAuthService } from '@tbx/experience-widgets-lib';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { types } from './constants';
import * as selectors from './selectors';

function* setValidationCode(action) {
  const { code } = action;

  const tokenData = yield select((state) => selectors.selectAccessToken(state));

  try {
    const { result } = yield call(
      unityAuthService.validateActivateCode,
      tokenData,
      { code },
    );

    if (!result || result.error) {
      throw new Error(result.error);
    }

    const { ok } = result || {};

    yield all([
      put(actions.fetchValidateCodeSuccess(ok)),
      put(actions.showMessageInform(true)),
    ]);
  } catch (e) {
    const errObj = e.error ? e.error : e;
    console.error('VALIDATION_CODE_ERROR: ', errObj);
    yield put(actions.fetchValidateCodeError(errObj));
  }
}

function* saga() {
  yield takeLatest(types.VALIDATE_CODE_FETCH, setValidationCode);
}

export default saga;
