/* eslint-disable no-unused-expressions */
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import FadeIn from 'react-fade-in';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import useRouter from '../../customHooks/useRouter';
import { usePlayerCallbackState } from '../App/playerCallbackContextProvider';

import { BurgerMenu, Imgcomponent } from '@tbx/experience-widgets-lib';
// #region /////// COMPONENTS ///////
import DropdownUserAccount from '../../components/DropdownUserAccount';
// #endregion

import { Button } from 'react-bootstrap';
import AVAILABLE_PATHS from '../../constants/pathRoutes';
import {
  getCurrentAvatar,
  getCurrentProfileData,
  isAdminProfile,
} from '../../utils/ProfileUtils';
import classNames from '../../utils/classnameUtils';
import { decodeToken } from '../../utils/jwtUtils';
import { toggleProfilesModal } from '../App/actions';
import { selectAccessToken, selectAuthenticated } from '../App/selectors';
import {
  changeEnableProfileOpeartions,
  changeFormProfileMode,
  changeProfileFormStatus,
  enablePinCodeValidateModal,
  fetchProfileById,
  setCurrentProfile,
  validatePinCodeProfileSuccess,
} from '../ProfileManager/actions';
import { MAX_RATING_DEFAULT } from '../ProfileManager/constants';
import {
  selectAvatarCollection,
  selectProfileCollection,
} from '../ProfileManager/selectors';
import { updateUserInput } from '../sections/SearchSection/actions';
import NavBarLink from './NavBarLink';
import {
  changeAccountMenuStatus,
  redirectToAccountInformation,
} from './actions';
import * as hooks from './hooks';
import {
  selectAccountMenuStatus,
  selectAppSettings,
  selectNavBarBool,
  selectPathList,
  selectSectionCollection,
  selectSticky,
} from './selectors';
import './styles.scss';
import useBrandInfo from '../../customHooks/useBrandInfo';
import { ReactSVG } from 'react-svg';
import useClientConfigs from '../../customHooks/useClientConfigs';

function Navbar({ sections = [], subSections = [] }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const playerCallbackState = usePlayerCallbackState();
  const pathname = router.pathname;
  const history = router.history;
  const [goSection, setGoSection] = React.useState(pathname);
  const [openMenu, setOpenMenu] = React.useState(false);
  const BRAND_INFO = useBrandInfo();
  const { hasPayService, hasPrivacyPolicyLink, hasCustomHelpSlug } =
    useClientConfigs();

  // #region /////// SELECTORS ///////
  const authenticated = useSelector((state) => selectAuthenticated(state));
  const avatarCollection = useSelector((state) =>
    selectAvatarCollection(state),
  );
  const profileCollection = useSelector((state) =>
    selectProfileCollection(state),
  );
  const stickyBool = useSelector((state) => selectSticky(state));
  const navBarBool = useSelector((state) => selectNavBarBool(state));
  const accountMenuOpen = useSelector((state) =>
    selectAccountMenuStatus(state),
  );
  const accessToken = useSelector((state) => selectAccessToken(state));
  const PATHS = useSelector((state) => selectPathList(state));
  const sectionsCollection = useSelector((state) =>
    selectSectionCollection(state),
  );
  const tokenData = accessToken.access_token
    ? decodeToken(accessToken.access_token)
    : {};
  const { profile } = tokenData;
  const { parentalControl } = useSelector((state) => selectAppSettings(state));
  // #endregion

  const currentAvatar = getCurrentAvatar(
    avatarCollection,
    profileCollection,
    profile,
  );
  const currentProfileSelected = getCurrentProfileData(
    profileCollection,
    profile,
  );
  const {
    hasPIN: hasPINProfileSelected,
    max_rating: maxRatingProfileSelected,
  } = currentProfileSelected || {};
  const adminProfile = isAdminProfile(currentProfileSelected?.max_rating);

  const handleOnClickLogin = (e) => {
    e.preventDefault();
    const { origin, pathname } = window.location;
    const returnURL = origin + pathname;

    history.push(PATHS.login, { returnURL });
  };

  const handleOnClickLogout = (e) => {
    playerCallbackState.clearPlayerCallback();
    e.preventDefault();
    history.push(PATHS.logout, { returnURL: origin });
  };

  const handleOnClickLogo = () => {
    // reset input state
  };

  const handleOnClickSearch = (e) => {
    e.preventDefault();
    if (!router.pathname.includes(PATHS.search)) {
      history.push(PATHS.search, { oneBack: { pathname } });
    } else {
      dispatch(updateUserInput(''));
    }
  };

  const findSectionImg = (images) => {
    return images?.length > 0 && images[0]?.url;
  };

  // #region /////// HOOKS ///////
  const { scrolling } = hooks.useScrolling();
  const [{ sortedSections }, { sortedSubSections }] = hooks.useDidMount(
    sections,
    subSections,
  );
  // #endregion

  const onSectionSelected = (section) => {
    history.push(`${section}`);
    setGoSection(section === '/' ? PATHS.home : section);
    setOpenMenu(false);
  };

  const handleOpenMenuProfile = () => dispatch(changeAccountMenuStatus(true));

  const handleOnSelectProfile = (profileID) => {
    hasPINProfileSelected && maxRatingProfileSelected < MAX_RATING_DEFAULT.ADULT
      ? handleEnablePinCodeValidateModal(profileID)
      : handleOnSelectProfileWithoutPinCode(profileID);
  };

  const handleOnSelectProfileWithoutPinCode = (profileID) => {
    playerCallbackState.clearPlayerCallback();
    dispatch(changeAccountMenuStatus(false));
    dispatch(setCurrentProfile(profileID));
  };

  const handleMyProfileEdit = () => {
    playerCallbackState.clearPlayerCallback();
    dispatch(fetchProfileById(profile));
    dispatch(toggleProfilesModal(true));
    dispatch(changeProfileFormStatus(true));
    dispatch(changeFormProfileMode(false));
  };

  const handleManageProfiles = () => {
    playerCallbackState.clearPlayerCallback();
    dispatch(toggleProfilesModal(true));
    dispatch(changeEnableProfileOpeartions(true));
  };

  const setOpenProfileMenu = (status) => {
    dispatch(changeAccountMenuStatus(status));
  };

  const handleEnablePinCodeValidateModal = (profileIDSelected) => {
    playerCallbackState.clearPlayerCallback();
    dispatch(toggleProfilesModal(true));
    dispatch(enablePinCodeValidateModal(true, profileIDSelected));
    dispatch(validatePinCodeProfileSuccess(true, profileIDSelected));
  };

  const handleRedirect = useCallback(
    (pathName = '') => {
      if (!pathName) return;

      const section = sectionsCollection.find(
        (sectionData) => sectionData?.url?.toLowerCase() === pathName,
      );

      if (!section) return;

      history.push(section?.url);
    },
    [sectionsCollection],
  );

  const profileOptionsProps = [
    {
      name: t('Terms of service'),
      handleOnClick: () => handleRedirect(AVAILABLE_PATHS.termAndUses),
    },
    {
      name: t('Help'),
      handleOnClick: () =>
        handleRedirect(hasCustomHelpSlug ? '/ayuda-web' : AVAILABLE_PATHS.help),
    },
    {
      name: t('Sign Out'),
      handleOnClick: handleOnClickLogout,
    },
  ];

  hasPrivacyPolicyLink
    ? profileOptionsProps.unshift({
        name: t('Privacy policy'),
        handleOnClick: () => handleRedirect(AVAILABLE_PATHS.privacy),
      })
    : '';

  const handleOnClickMyAccount = (e) => {
    dispatch(redirectToAccountInformation(accessToken));
  };

  let profileOperationProps = [];

  if (parentalControl) {
    profileOperationProps = [
      {
        name: adminProfile ? t('Manage Profiles') : t('My profile'),
        handleOnClick: adminProfile
          ? handleManageProfiles
          : handleMyProfileEdit,
      },
    ];
  }

  hasPayService
    ? profileOperationProps.push({
        name: t('My Account'),
        handleOnClick: handleOnClickMyAccount,
      })
    : '';

  return (
    <React.Fragment>
      {navBarBool && (
        <nav
          className={classNames(
            'navigation',
            scrolling ? 'black' : '',
            'stickyBool-' + stickyBool,
            'subSection-' + (sortedSubSections?.length > 0),
          )}
          style={{ position: stickyBool ? 'sticky' : 'fixed' }}
        >
          <FadeIn>
            <ul className="navigation__container">
              <div className="navigation__container--menuTrigger">
                <BurgerMenu
                  openMenu={openMenu}
                  setOpenMenu={setOpenMenu}
                  selectedKey={goSection === '/' ? '/home' : goSection}
                  onSectionSelected={onSectionSelected}
                  sections={sortedSections}
                />
              </div>
              <NavLink
                to={PATHS.home}
                onClick={() => handleOnClickLogo()}
                className="navigation__container__logo"
              >
                <Imgcomponent
                  className="logoContainer"
                  src={'/images/logo.svg'}
                  alt={BRAND_INFO.CLIENT_NAME}
                  loading={'eager'}
                />
              </NavLink>
              {sections.length > 0 && (
                <React.Fragment>
                  <Imgcomponent
                    className="navigation__container--downArrow-2"
                    src="/images/drop-down-arrow.sv"
                    alt="downArrow-logo"
                  />
                  {sortedSections.map((section, index) => {
                    const urlImage = findSectionImg(section?.images);
                    return (
                      <NavBarLink
                        key={section.id}
                        section={section}
                        index={index}
                        urlImage={urlImage}
                      />
                    );
                  })}
                </React.Fragment>
              )}
              <div className="navigation__container--left"></div>
              <NavLink onClick={handleOnClickSearch} to={PATHS.search}>
                <Imgcomponent
                  className="navigation__container--searchLogo"
                  src="/images/search-icon.svg"
                  alt="search"
                />
              </NavLink>
              {!authenticated ? (
                <Button
                  className="loginButton"
                  onClick={(e) => handleOnClickLogin(e)}
                >
                  <ReactSVG
                    className="accountLogo"
                    src="/images/person-circle.svg"
                    alt="Login"
                  />
                  <span className="textLoginButton">
                    {t('Get In').toLocaleUpperCase()}
                  </span>
                </Button>
              ) : (
                <DropdownUserAccount
                  handleOpenMenuProfile={handleOpenMenuProfile}
                  onClickSignOutHandler={handleOnClickLogout}
                  currentAvatar={currentAvatar}
                  profiles={parentalControl ? profileCollection : []}
                  avatars={avatarCollection}
                  openProfileMenu={accountMenuOpen}
                  setOpenProfileMenu={setOpenProfileMenu}
                  handleOnSelectProfile={handleOnSelectProfile}
                  profileOperationProps={profileOperationProps}
                  profileOptionsProps={profileOptionsProps}
                  currentProfileSelected={currentProfileSelected}
                  isAdminProfileSelected={adminProfile}
                  handleEnablePinCodeValidateModal={
                    handleEnablePinCodeValidateModal
                  }
                />
              )}
              {/* <BellLogo className="navigation__container--bellLogo" /> */}
              {/* <LanguageSelector /> */}
            </ul>
            {/* {sortedSubSections?.length > 0 &&
              <SubNavBar
                sections={sortedSubSections}
                image={sectionActive ? findSectionImg(sectionActive?.images) : null}
                open={!scrolling} />} */}
          </FadeIn>
        </nav>
      )}
    </React.Fragment>
  );
}

Navbar.propTypes = {
  sections: PropTypes.array,
};

export default Navbar;
