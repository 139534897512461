/**
 * Verifica si una lista de propiedades están definidas en el objeto
 *
 * @param  {*}      props  Props a buscar
 * @param  {*}      objeto Objeto a verificar props
 * @return {Object}        Tupla de status y props encontradas
 */
export const checkifPropertiesExist = (props, objeto) => {
  const propsArray = Array.isArray(props) ? props : [props.toString()];

  const result = {
    status: false,
    keys: [],
  };

  result.keys = propsArray
    .map((item) => {
      result.status = Object.prototype.hasOwnProperty.call(objeto, item);

      return result.status ? item : null;
    })
    .filter((item) => item);

  return result;
};

/**
 * Verifies if object is empty or has empty attributes.
 *
 * @param  {*}      objeto Objeto a verificar props
 * @return {Boolean}
 */
export const checkifEmptyObject = (objeto) => {
  return Object.values(objeto).every((x) => x === null || x === '');
};

/**
 * Get object's size
 *
 * @param  {*}      objeto
 * @return {number}
 */
export const getObjectSize = (objeto) => {
  return Object.keys(objeto).length;
};

export const removeDuplicates = (originalArray, keys) => {
  const filtered = originalArray.filter(
    (
      (s) => (o) =>
        ((k) => !s.has(k) && s.add(k))(keys.map((k) => o[k]).join('|'))
    )(new Set()),
  );
  return filtered;
};

/**
 * Convert object properties and values to string pharagraph.
 * @param {Object} obj
 */
export const objToString = (obj) => {
  let str = '';
  for (const p in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(p)) {
      str += p + ': ' + obj[p] + '\n';
    }
  }
  return str;
};

/**
 * Removes empty keys in object.
 * @param {Object} obj
 */
export const removeEmptyKeys = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object') removeEmptyKeys(obj[key]);
    else if (obj[key] === null || typeof obj[key] === 'undefined')
      delete obj[key];
  });

  return obj;
};

/* export const sortElementsByOrderKey = (itemCollection) => {
    // temporary array holds objects with position
    // and length of element
    const lengths = itemCollection.map(function (e, i) {
        return {index: i, value: e.length };
    });

    // sorting the lengths array containing the lengths of
    // river names
    lengths.sort(function (a, b) {
        return +(a.order > b.order) || +(a.order === b.order) - 1;
    });

    // copy element back to the array
    const sorted = lengths.map(function (e) {
        return itemCollection[e.index];
    });

    return sorted;
}; */

/**
 * Truncate string
 */
export const truncateString = (input, maxChars) => {
  if (!input) {
    return '';
  }
  return input.length > maxChars
    ? `${input.substring(0, maxChars - 4)}...`
    : input;
};
