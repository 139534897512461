export const TITLES = [
  'Play now',
  'Play in',
  'Available in',
  'Go to site',
  'Go to path',
  'Live',
  'schedule',
  'at',
  'Live on',
  'hr',
  'At this time there are no content available.',
];

export const FILTER_ORDER_TYPES = [
  { name: 'title', orderType: 'title' },
  { name: 'popularity', orderType: 'popularity' },
  { name: 'recent', orderType: 'release' },
];
