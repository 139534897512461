import { call, put, takeLatest, select } from 'redux-saga/effects';
import { unityContentsService } from '@tbx/experience-widgets-lib';

import * as actions from './actions';
import { types, CONTENT_TYPES } from './constants';
import * as selectors from './selectors';

function* fetchContentWidget(action) {
  const { accessToken, contentID } = action;

  try {
    const { result } = yield call(
      unityContentsService.getContentsById,
      accessToken,
      contentID,
    );

    if (!result || result.error) {
      throw new Error(result.error);
    }

    yield put(actions.loadWidgetSuccess(result));
  } catch (e) {
    console.error('CONTENTOVERVIEW_SECTION_WIDGET_FETCH_ERROR: ', e);
    yield put(actions.loadWidgetError(e));
  }
}

function* fetchContentRelatedWidget(action) {
  const { content } = action;
  try {
    const currentToken = yield select((state) =>
      selectors.selectAccessToken(state),
    );

    const contentTypes = content.contentType?.toUpperCase() ?? '';

    const dataResult = yield call(
      CONTENT_TYPES[content.contentType].SERVICE,
      currentToken,
      content.id,
      { contentType: contentTypes },
    );

    if (!dataResult || dataResult.error) {
      throw new Error(dataResult.error);
    }

    yield put(
      actions[CONTENT_TYPES[content.contentType].LOAD_ACTION](
        dataResult?.result,
      ),
    );
  } catch (e) {
    console.error('CONTENTEPISODES_WIDGET_FETCH_ERROR: ', e);
    yield put(actions.loadWidgetError(e));
  }
}

function* fetchContensUrlById(action) {
  const { accessToken, contentID } = action;

  try {
    const { result } = yield call(
      unityContentsService.getContentsUrlById,
      accessToken,
      contentID,
      {},
    );

    if (!result || result.error) {
      throw new Error(result.error);
    }

    yield put(actions.getContentsUrlByIdSuccess(result));
  } catch (e) {
    console.error('CONTENTOVERVIEW_SECTION_CONTENTS_URL_FETCH_ERROR: ', e);
    yield put(actions.getContentsUrlByIdError(e));
  }
}

function* saga() {
  yield takeLatest(types.WIDGET_FETCH, fetchContentWidget);
  yield takeLatest(types.WIDGET_FETCH_SUCCESS, fetchContentRelatedWidget);
  yield takeLatest(types.CONTENTS_URL_ID_FETCH, fetchContensUrlById);
}

export default saga;
