import React from 'react';
import useRouter from '../../customHooks/useRouter';
import { REDIRECT_TIME } from './constants';

/**
 * DidMount like hook effect
 *
 * @export
 */
export function useDidMount(PATHS) {
  const router = useRouter();

  React.useEffect(() => {
    const returnToHome = setInterval(() => {
      router.push(PATHS.home);
    }, REDIRECT_TIME.SECTION_NOT_FOUND);

    return () => clearInterval(returnToHome);
  }, []);
}
