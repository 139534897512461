import React from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import './styles.scss';

function SocialSharingList() {
  const currentUrl = window.location.href;
  return (
    <ul className="social_sharing">
      <li>
        <FacebookShareButton url={currentUrl}>
          <i className="bi bi-facebook"></i>
          Facebook
        </FacebookShareButton>
      </li>
      <li>
        <TwitterShareButton url={currentUrl}>
          <i className="bi bi-twitter-x"></i>X
        </TwitterShareButton>
      </li>
      <li>
        <WhatsappShareButton url={currentUrl}>
          <i className="bi bi-whatsapp"></i>
          Whatsapp
        </WhatsappShareButton>
      </li>
    </ul>
  );
}

export default SocialSharingList;
