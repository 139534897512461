import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { decodeToken } from '../../utils/jwtUtils';
import { selectAuthenticated } from '../sections/HomeSection/selectors';

import * as actions from './actions';

import { selectUnityAuthData } from '../../containers/App/selectors';
import { PATHS_CONST, PATHS_CONST_WITHOUT_COUNTRY } from './constants.js';

/**
 * Retrieve app sections when token changes effect
 *
 * @export
 */
export function useSectionsFetch(accessToken) {
  const dispatch = useDispatch();
  const authenticated = useSelector((state) => selectAuthenticated(state));
  const unityAuthData = useSelector((state) => selectUnityAuthData(state));

  React.useEffect(() => {
    if (accessToken && !authenticated) {
      dispatch(actions.loadAppSections(accessToken, unityAuthData));
    } else {
      const tokenData = accessToken.access_token
        ? decodeToken(accessToken.access_token)
        : {};
      const { profile } = tokenData;

      if (profile) {
        dispatch(actions.loadAppSections(accessToken, unityAuthData));
      }
    }
  }, [accessToken, dispatch]);
}

export const usePathsHok = (pathCountry) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    Object.values(PATHS_CONST_WITHOUT_COUNTRY).forEach((path) => {
      if (pathCountry === path)
        throw Error('Identical route to country: ' + pathCountry);
    });
    Object.values(PATHS_CONST).forEach((path) => {
      if (pathCountry === path)
        throw Error('Identical route to country: ' + pathCountry);
    });
    const pathsResult = {};
    Object.entries(PATHS_CONST).forEach(([key, path]) => {
      pathsResult[key] = pathCountry + path;
    });
    dispatch(actions.pathRoutesAdd(pathsResult));
  }, [pathCountry]);
};
