import React from 'react';
import { withRouter } from 'react-router';
import { HandleError } from '../../components/HandleError';
import { PATH_ERROR } from '../../containers/AppSectionRouter/constants';
import { parseErrorToNewRelic } from '../../utils/newRelicUtils';

/**
 * FatalErrorBoundary, ErrorBoundary para toda la Aplicación.
 *
 * @param {*} {
 *   WrappedComponent
 * }
 */
/** FatalErrorBoundary */
export const FatalErrorBoundary = function (WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = { error: false };
    }

    static getDerivedStateFromError(error) {
      return { error };
    }

    render() {
      if (this.state.error) {
        return <HandleError error={this.state.error} navBar={false} />;
      } else {
        return <WrappedComponent {...this.props} />;
      }
    }
  };
};

/**
 * SectionErrorBoundary, ErrorBoundary para cada seccion.
 *
 * @param {*} {
 *   WrappedComponent
 * }
 */
/** SectionErrorBoundary */
export const SectionErrorBoundary = function (WrappedComponent) {
  return withRouter(
    class extends React.Component {
      constructor(props) {
        super(props);
        this.state = { boolError: false };
      }

      componentDidCatch = (error, errorInfo) => {
        const { history } = this.props;
        this.setState({ boolError: true });
        const errorResult = { error, errorInfo };

        if (typeof error === 'string') {
          parseErrorToNewRelic(error);
        }

        if (typeof error === 'object') {
          parseErrorToNewRelic({ ...error, ...errorInfo });
        }

        history.push(PATH_ERROR, { error: errorResult, navBar: true });
      };

      render() {
        if (this.state.boolError) {
          return <div>error</div>; // mientras se redirige a /error
        }
        // Normally, just render children
        return <WrappedComponent {...this.props} />;
      }
    },
  );
};
