import { createSelector } from 'reselect';

const getReducer = (state) => state.containers.navbar;
const getAppSectionsReducer = (state) => state.containers.appSections;
const getAppReducer = (state) => state.containers.app;

export const selectUserInput = createSelector(
  getReducer,
  (reducer) => reducer.userInput,
);

export const selectNavBarBool = createSelector(
  getReducer,
  (reducer) => reducer.navBarBool,
);

export const selectSticky = createSelector(
  getReducer,
  (reducer) => reducer.sticky,
);

export const selectAccountMenuStatus = createSelector(
  getReducer,
  (reducer) => reducer.accountMenuOpen,
);

export const selectPathList = createSelector(
  getAppSectionsReducer,
  (reducer) => reducer.pathList,
);

export const selectPathCountry = createSelector(
  getAppSectionsReducer,
  (reducer) => reducer.pathCountry,
);

export const selectAppSettings = createSelector(
  getAppReducer,
  (reducer) => reducer.appSettings,
);

export const selectSectionCollection = createSelector(
  getAppSectionsReducer,
  (reducer) => reducer.sectionCollection,
);
