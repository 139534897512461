import { types } from './constants';

const INITIAL_STATE = {
  activationCodeStatus: false,
  error: {
    code: null,
    details: null,
    message: null,
  },
  isFetching: false,
  showMessage: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.VALIDATE_CODE_FETCH:
      return {
        ...state,
        isFetching: true,
        showMessage: false,
      };
    case types.VALIDATE_CODE_FETCH_FAILURE:
      return {
        ...state,
        error: {
          ...state.error,
          code: action.error.errorCode,
          message: `"${action.error.name}": ${action.error.message}`,
        },
        isFetching: false,
      };
    case types.VALIDATE_CODE_FETCH_SUCCESS:
      return {
        ...state,
        activationCodeStatus: action.status,
        isFetching: false,
      };
    case types.SHOW_MESSAGE:
      return {
        ...state,
        showMessage: action.status,
      };
    default:
      return state;
  }
};

export default reducer;
