import React from 'react';
import { useTranslation } from 'react-i18next';
import useRouter from '../../customHooks/useRouter';

/**
 * DidMount like hook effect
 *
 * @export
 */
export function useDidMount(sections, subSections) {
  const router = useRouter();
  const { t } = useTranslation();
  const [sectionActive, setSectionActive] = React.useState(null);
  const [subSectionActive, setSubSectionActive] = React.useState(null);

  const sortedHelp = (array) =>
    array?.map((section) => {
      return { ...section, name: t(section.name) };
    });

  const pathnameHelp = (cadena, i) => {
    return '/' + (cadena.split('/')[i] || '');
  };

  React.useEffect(() => {
    const isSectionActive = sections.find(
      ({ url }) => pathnameHelp(router.pathname, 2) === url,
    );
    setSectionActive(isSectionActive);
  }, [router.pathname, sections]);

  React.useEffect(() => {
    const isSubSectionActive = subSections.find(
      ({ url }) => pathnameHelp(router.pathname, 3) === url,
    );
    setSubSectionActive(isSubSectionActive);
  }, [router.pathname, subSections]);

  return [
    {
      sortedSections: sortedHelp(sections),
      sectionActive,
    },
    {
      sortedSubSections: sortedHelp(subSections)?.filter(
        (section) => section.parent === sectionActive?.id,
      ),
      subSectionActive,
    },
  ];
}

export function useScrolling() {
  const [scrolling, setScrolling] = React.useState(false);

  /** changes the scrolling state depending on the Y-position */
  const handleScroll = React.useCallback(() => {
    if (window.scrollY === 0) {
      setScrolling(false);
    } else if (window.scrollY > 50) {
      setScrolling(true);
    }
  }, []);

  // #region /////// HOOKS ///////
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);
  // #endregion

  return {
    scrolling,
  };
}
