import { types } from './constants';

export const loadWidgets = (accessToken, sectionID, filters = {}) => ({
  type: types.WIDGETS_FETCH,
  accessToken,
  filters,
  sectionID,
});

export const loadWidgetsError = (error) => ({
  type: types.WIDGETS_FETCH_FAILURE,
  error,
});

export const loadWidgetsSuccess = (widgetsCollection) => ({
  type: types.WIDGETS_FETCH_SUCCESS,
  widgetsCollection,
});

export const selectContent = (content) => ({
  type: types.CONTENT_SELECTED,
  content,
});

export const toggleModal = (show) => ({
  type: types.TOGGLE_MODAL,
  show,
});

export const getCurrentEpisodeBySerie = (contentID, router, pathPlayer) => ({
  type: types.GET_EPISODE_BY_SERIE,
  contentID,
  router,
  pathPlayer,
});

export const loadMoreWidgets = () => ({
  type: types.WIDGETS_LOAD_MORE,
});
