import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { handleClick, handleMouseEnter } from './utils';

import './styles.scss';

function HtmlComponent({ code, csscode }) {
  const history = useHistory();

  const [currentScroll, setScroll] = React.useState(0);

  const cssCode = `
    <style>
      ${csscode}
    </style>
  `;

  return (
    <section
      className="html-component"
      dangerouslySetInnerHTML={{
        __html: `
        ${csscode && cssCode}
        ${code}
      `,
      }}
      onMouseEnter={() =>
        handleMouseEnter({
          currentScroll,
          setScroll,
        })
      }
      onClick={(event) =>
        handleClick({
          event,
          history,
          currentScroll,
          setScroll,
        })
      }
    ></section>
  );
}

HtmlComponent.propTypes = {
  code: PropTypes.string.isRequired,
  csscode: PropTypes.string,
  jscode: PropTypes.string,
};

export default HtmlComponent;
