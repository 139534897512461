const BUILD_TIMESTAMP =
  (window.__TBX_ENV__ && window.__TBX_ENV__.REACT_APP_BUILDTIME) || '';

const getURLwithBuildTimeParam = (url) => {
  const appendString = /[?&]/.test(url)
    ? `&v=${BUILD_TIMESTAMP}`
    : `?v=${BUILD_TIMESTAMP}`;
  return url + appendString;
};

/**
 * Data downloading using pure JS fetch
 *
 * @param  {*}       url     URL to fetch data
 * @param  {*}       options Request options
 * @return {Promise}         Fetch promise
 */
export const fetchData = (url, options) => {
  const completeURL = getURLwithBuildTimeParam(url);
  const fetchRequest = new Request(completeURL, options);

  return fetch(fetchRequest).then(async (response) => {
    let result = {};

    if (!response.ok) {
      result.statusCode = response.status;

      result = await response.json();
      console.debug('RESPONSE NOOK >>>', JSON.stringify(result));
      if (!result || Object.keys(result).length === 0) {
        result = result || {};
        result.error = 'Unknown server error!';
      }

      throw result;
    }

    if (response.redirected) {
      window.location.href = response.url;
    }

    return response.json().then((result) => ({ result }));
  });
};

/**
 * Check if url is strictly external
 *
 * @param  {*}       url     external URL
 * @return {Boolean}         if url is external (the domain is not the same as the current page) returns true
 */
export const isExternalURl = (url) => {
  const isExternal = new URL(url).origin !== document.location.origin;
  return isExternal;
};
