import { createSelector } from 'reselect';

const getAppReducer = (state) => state.containers.app;
const getReducer = (state) => state.containers.appSections;
const getSessionReducer = (state) => state.session;

export const selectAccessToken = createSelector(
  getSessionReducer,
  (reducer) => reducer.accessToken,
);

export const selectAppSettings = createSelector(
  getAppReducer,
  (reducer) => reducer.appSettings,
);

export const selectError = createSelector(getReducer, (reducer) => {
  return reducer.error;
});

export const selectIsFetching = createSelector(
  getReducer,
  (reducer) => reducer.isFetching,
);

export const selectSectionCollection = createSelector(
  getReducer,
  (reducer) => reducer.sectionCollection,
);

export const selectPathList = createSelector(
  getReducer,
  (reducer) => reducer.pathList,
);

export const selectPathCountry = createSelector(
  getReducer,
  (reducer) => reducer.pathCountry,
);

export const selectUnityAuthData = createSelector(
  getAppReducer,
  (reducer) => reducer.unityAuthData,
);

export const selectAuthenticated = createSelector(
  getSessionReducer,
  (reducer) => reducer.authenticated,
);
