import { unityEPGService } from '@tbx/experience-widgets-lib';
import { addDays, endOfDay, startOfDay, subDays } from 'date-fns';
import React from 'react';
import * as actions from './actions';

/**
 * DidMount like hook effect
 *
 * @export
 */
export function useDidMount(
  content,
  unityToken,
  daysBefore,
  daysAfter,
  selectedEpgDataByIds,
  selectedContent,
  dispatch,
) {
  const [contentCopy, setContentCopy] = React.useState();
  const [epgIdsState, setEpgIdsState] = React.useState([]);

  React.useEffect(() => {
    if (JSON.stringify(contentCopy) !== JSON.stringify(content)) {
      setContentCopy(content);
      if (content?.length > 0 && !selectedContent) {
        const dateFrom = startOfDay(subDays(Date.now(), daysBefore));
        const dateTo = endOfDay(addDays(Date.now(), daysAfter));
        const epgIds = content.map((e) => e.epgId);
        if (JSON.stringify(epgIds) !== JSON.stringify(epgIdsState)) {
          setEpgIdsState(epgIds);
          fetchEPGDataByIds({
            unityToken,
            epgID: epgIds,
            from: dateFrom,
            to: dateTo,
            dispatch,
          });
        }
      }
    }
  }, [content]);

  React.useEffect(() => {
    if (selectedEpgDataByIds) {
      const copyContent = content
        .map((c, index) => {
          const resultFind = selectedEpgDataByIds.find((r) => {
            return c.epgId === r.epgId;
          });
          if (resultFind?.epg) {
            return {
              ...content[index],
              epg: resultFind.epg,
            };
          }
          return false;
        })
        .filter((bool) => !!bool);
      dispatch(actions.selectContent(copyContent[0], copyContent));
    }
  }, [selectedEpgDataByIds]);
}

async function fetchEPGDataByIds(action) {
  const { unityToken, epgID, from, to, dispatch } = action;

  try {
    const { result } = await unityEPGService.fetchEPGDataByIds(unityToken, {
      epgID,
      from: from.toISOString(),
      to: to.toISOString(),
    });
    if (!result || result.error) {
      throw new Error(result);
    } else {
      dispatch(actions.setEpgDataByIdsSuccess(result));
    }
  } catch (e) {
    const errObj = e.error ? e.error : e;
    console.error('FETCH_EPG_DATA_BY_IDS_ERROR: ', errObj);
    dispatch(actions.setEpgDataByIdsError(errObj));
  }
}
