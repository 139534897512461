import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import '@tbx/config/i18n';
import App from '@tbx/containers/App';
// import config from './config';
import LandingLoader from '@tbx/components/LandingLoader';
import reportWebVitals from './reportWebVitals';
import store, { history, persistor } from '@tbx/state/store';

import './static/sass/style.scss';

// INFO: Para load de datos con la carga de lo persistido en el store:
// const handleOnBeforeLift = async (store) => {
// };

const app = document.getElementById('root');

// create a root
const root = createRoot(app);

root.render(
  <Provider store={store}>
    <PersistGate
      loading={<LandingLoader />}
      // onBeforeLift={() => handleOnBeforeLift(store)}
      persistor={persistor}
    >
      <App history={history} />
    </PersistGate>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
