import { types } from './constants';

export const clearSearch = () => ({
  type: types.SEARCH_INPUT_CLEAR,
});

export const loadMoreContents = (results) => ({
  type: types.SEARCH_LOAD_MORE_CONTENTS,
  results,
});

export const searchContents = (tokenData, filters) => ({
  type: types.SEARCH_CONTENTS,
  tokenData,
  filters,
});

export const searchContentsFailure = (errors) => ({
  type: types.SEARCH_CONTENTS_FAILURE,
  errors,
});

export const searchContentsSuccess = (results, recommended, emptyResult) => ({
  type: types.SEARCH_CONTENTS_SUCCESS,
  results,
  recommended,
  emptyResult,
});

export const updateUserInput = (text) => ({
  type: types.SEARCH_INPUT_CHANGE,
  text,
});
