export const CONTENT_TYPES = {
  MOVIE: 'MOVIE',
  SERIE: 'SERIE',
  EPISODE: 'EPISODE',
  BROADCAST: 'BROADCAST',
  SPORT: 'SPORT',
};

export const IMAGE_MODE = {
  POSTER: 'POSTER',
  THUMB: 'THUMB',
  SIMPLE_LARGE: 'SIMPLE_LARGE',
};

export const SUBPATHS = {
  LIVE: {
    subPath: 'live',
    contentTypeOptions: [CONTENT_TYPES.BROADCAST, CONTENT_TYPES.SPORT],
  },

  CONTENT: {
    subPath: 'content',
    contentTypeOptions: [
      CONTENT_TYPES.MOVIE,
      CONTENT_TYPES.SERIE,
      CONTENT_TYPES.EPISODE,
    ],
  },
};

export const PROFILE_NAMES = {
  DEFAULT: 'default',
};

export const REGULAR_EXPRESSION = {
  SEARCH: /(participant:|genres:|text:)/g,
};

export const DEFAULT_TOPIC = {
  ID: '1',
  NAME: 'defaultTopic',
};

export const SHOW_ITEMS_BY_DEFAULT = {
  CAROUSEL: 20,
};

export const LIMIT_ITEMS = {
  HSTACK: 5,
  EPG_GRID: 5,
};

export const TRUCATE_STRING = {
  METATAGS_DESCRIPTION: 200,
};
