import React from 'react';
import ReactGA4 from 'react-ga4';

const TRACKING_ID = window.__TBX_ENV__.TBX_ANALYTICS_TRACKING_ID; // OUR_TRACKING_ID

const useAnalytics = () => {
  React.useEffect(() => {
    if (TRACKING_ID) ReactGA4.initialize(TRACKING_ID);
  }, []);
};

export default useAnalytics;
