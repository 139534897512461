import PLATFORM_INFO from '../constants/platforms';

/**
 * Find image setting from cloudhub data
 * @param {Object} obj
 * @param {*} imageType
 *
 * @return {Object}
 */
export const findImageSettingByType = (obj, imageType) => {
  const setting = obj?.find((element) => element.name === imageType);
  if (typeof setting !== 'object') return { w: false, h: false };
  return setting;
};

/**
 * Validate custom user agent for mobile's client
 *
 * @return {Boolean}
 */
export const checkUserAgentCustom = () => {
  const userAgent = navigator.userAgent;
  const USER_AGENT_PREFIXES = ['tbxandroidapp', 'tbxiosapp'];
  const result = USER_AGENT_PREFIXES.some((prefix) =>
    userAgent.startsWith(prefix),
  );

  return !!result;
};

/**
 * get Store Url by plataform
 *
 * @return {Boolean}
 */
export const getStoreUrlByPlataform = (mobileAppUrls, platform) => {
  const storeUrl = mobileAppUrls.find((p) => p.platform === platform);

  return storeUrl && storeUrl.url
    ? storeUrl.url
    : PLATFORM_INFO[platform].defaultStore;
};

/**
 * Validate subpath to redirect. Default value /content
 * @param {String} contentType
 *
 * @return {String}
 */
/*
 export const getSubPathByContentType = (contentType) => {

    const subPathsDefault = 'content';
    const subPathsObj = Object.values(SUBPATHS);

    for(const val in subPathsObj) {
        if(subPathsObj[val].contentTypeOptions.includes(contentType))
            return subPathsObj[val].subPath;
    }

    return subPathsDefault;
 
};
*/
