import { types } from './constants';

export const loadWidget = (accessToken, contentID) => ({
  type: types.WIDGET_FETCH,
  accessToken,
  contentID,
});

export const loadWidgetError = (error) => ({
  type: types.WIDGET_FETCH_FAILURE,
  error,
});

export const loadWidgetSuccess = (content) => ({
  type: types.WIDGET_FETCH_SUCCESS,
  content,
});

export const loadWidgetRelatedSuccess = (content) => ({
  type: types.RELATED_WIDGET_FETCH_SUCCESS,
  content,
});

export const loadWidgetEpisodesSuccess = (content) => ({
  type: types.EPISODES_WIDGET_FETCH_SUCCESS,
  content,
});

export const clearStates = () => ({
  type: types.CLEAR_STATES,
});

export const getEpisodeCurrentContentByProfile = (
  accessToken,
  contentID,
  profileID,
) => ({
  type: types.EPISODE_CURRENT_CONTENT_PROFILE_FETCH,
  accessToken,
  contentID,
  profileID,
});

export const getEpisodeCurrentContentByProfileSuccess = (content) => ({
  type: types.EPISODE_CURRENT_CONTENT_PROFILE_FETCH_SUCCESS,
  content,
});

export const getEpisodeCurrentContentByProfileError = () => ({
  type: types.EPISODE_CURRENT_CONTENT_PROFILE_FETCH_FAILURE,
});

export const getContentsUrlById = (accessToken, contentID) => ({
  type: types.CONTENTS_URL_ID_FETCH,
  accessToken,
  contentID,
});

export const getContentsUrlByIdError = (error) => ({
  type: types.CONTENTS_URL_ID_FETCH_FAILURE,
  error,
});

export const getContentsUrlByIdSuccess = (content) => ({
  type: types.CONTENTS_URL_ID_FETCH_SUCCESS,
  content,
});
