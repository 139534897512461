export const types = {
  CONTENT_SELECTED: 'HomeSection/CONTENT_SELECTED',
  TOGGLE_MODAL: 'HomeSection/TOGGLE_MODAL',
  TOGGLE_PLAYER: 'HomeSection/TOGGLE_PLAYER',
  WIDGETS_FETCH: 'HomeSection/WIDGETS_FETCH',
  WIDGETS_FETCH_FAILURE: 'HomeSection/WIDGETS_FETCH_FAILURE',
  WIDGETS_FETCH_SUCCESS: 'HomeSection/WIDGETS_FETCH_SUCCESS',
  GET_EPISODE_BY_SERIE: 'HomeSection/GET_EPISODE_BY_SERIE',
  WIDGETS_LOAD_MORE: 'HomeSection/WIDGETS_LOAD_MORE',
};
