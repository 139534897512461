import { useTranslation } from 'react-i18next';

export default function useBrandInfo() {
  const { t } = useTranslation();

  return {
    CLIENT_NAME: t('Brand'),
    CLIENT_DESCRIPTION: t('Brand Description'),
  };
}
