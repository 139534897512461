import React, { useReducer, useCallback } from 'react';
import useRouter from './useRouter';

// Initial state that we pass into useReducer
const initialState = {
  // Array of previous state values updated each time we push a new state
  past: [],
  // Current state value
  present: null,
};

// Our reducer function to handle state changes based on action
const reducer = (state, action) => {
  const { past, present } = state;
  switch (action.type) {
    case 'ADDKEY':
      const { newKey } = action;
      const presentCopy = { ...present };
      if (newKey !== presentCopy.key[0]) presentCopy.key.unshift(newKey);
      return {
        past,
        present: presentCopy,
      };
    case 'ADD':
      const { newPresent } = action;
      return {
        past: present ? [present, ...past] : [...past],
        present: newPresent,
      };
  }
};

const useStoreTime = () => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  });

  const add = useCallback(
    (newPresent) => dispatch({ type: 'ADD', newPresent }),
    [dispatch],
  );

  const addKey = useCallback(
    (newKey) => dispatch({ type: 'ADDKEY', newKey }),
    [dispatch],
  );

  const { location } = useRouter();

  React.useEffect(() => {
    const { present } = state;
    if (location.pathname !== present?.pathname) {
      add({ pathname: location.pathname, key: [location.key] });
    } else {
      addKey(location.key);
    }
  }, [location]);

  return state;
};

export default useStoreTime;
