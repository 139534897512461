import React, { useEffect } from 'react';
import './styles.scss';

// #region /////// TBX WIDGETS ///////
import { cpassAuthService } from '@tbx/experience-widgets-lib';
import { useDispatch, useSelector } from 'react-redux';
import { CONTENT_PROVIDER_AUTH } from '../../constants/contentProviderAuth';
import * as selectors from '../../containers/AppSectionRouter/selectors';
import { redirectToAccountInformation } from '../../containers/Navbar/actions';
import { getIdpClient } from '../../utils/cloudpassUtilis';
import Loading from '../LoadingSpinner';

// #endregion

function DownloadApp({ mobileAppUrls }) {
  const dispatch = useDispatch();

  const unityAuthData = useSelector((state) =>
    selectors.selectUnityAuthData(state),
  );
  const authenticated = useSelector((state) =>
    selectors.selectAuthenticated(state),
  );
  const accessToken = useSelector((state) =>
    selectors.selectAccessToken(state),
  );

  const handleOnClickMyAccount = (e) => {
    dispatch(redirectToAccountInformation(accessToken));
  };

  const goToSignUp = () => {
    const idpClient = getIdpClient(unityAuthData.client);
    const userCountry =
      window.__TBX_ENV__.TBX_FORCE_COUNTRY || unityAuthData.country;
    const loginURL = cpassAuthService.getLoginURL(
      idpClient,
      CONTENT_PROVIDER_AUTH.IDP,
      userCountry,
      window.location.origin,
      null,
    );

    return loginURL;
  };

  useEffect(() => {
    if (!authenticated) {
      const url = goToSignUp();
      window.location.replace(url);
      return;
    }

    handleOnClickMyAccount();
  }, []);

  return <Loading />;
}

export default DownloadApp;
