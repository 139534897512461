import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import useRouter from '../../customHooks/useRouter';
import { useTranslation } from 'react-i18next';
import * as hooks from './hooks.js';

export function RedirectHandleError() {
  const router = useRouter();
  const { error, navBar } = router.location.state;
  return HandleError({ error, navBar });
}

export function HandleError({ error, navBar = true }) {
  const { t } = useTranslation();
  const errorTitle =
    (error?.error && error.error.toString()) ||
    (error?.code && error.code.toString());
  const errorInfo =
    error?.errorInfo?.componentStack ||
    error?.stack ||
    (error?.message && error.message.toString());
  hooks.useChangeNavBarBool(navBar);

  return (
    <main className="main-content">
      <div className="tbxHandleError">
        <div className="tbxHandleError-container">
          <img
            className="tbxHandleError-logo"
            src="/images/logo.png"
            alt="Toolbox GO"
          />
          <h3 className="tbxHandleError-title">{t('HandleError Title')}</h3>
          <h5 className="tbxHandleError-subTitle">
            {t('HandleError SubTitle')}
          </h5>
          <details
            className="tbxHandleError-details"
            style={{ whiteSpace: 'pre-wrap' }}
          >
            <summary className="tbxHandleError-summary">ERROR CODE</summary>
            <div className="tbxHandleError-errorText">
              {errorTitle}
              <br />
              {errorInfo}
            </div>
          </details>
        </div>
      </div>
    </main>
  );
}

HandleError.propTypes = {
  error: PropTypes.object,
  navBar: PropTypes.bool,
};
