import React from 'react';
import PropTypes from 'prop-types';
import {
  isAdminProfile,
  validateProfileOperations,
} from '../../../utils/ProfileUtils';
import classNames from '../../../utils/classnameUtils';
import './styles.scss';
import { MAX_RATING_DEFAULT } from '../../../containers/ProfileManager/constants';
function ProfileItem({
  avatarURI = null,
  name,
  className,
  onClickHandler,
  handleOnEditProfile,
  handleConfirmDeleteProfile,
  profileData,
  profileCollection,
  enableProfileOperations,
  currentDataProfileSelected,
}) {
  let avatarStyle = {};
  const { max_rating: maxRatingProfileSelected } =
    currentDataProfileSelected || {};
  const { hasPIN } = profileData || {};

  if (avatarURI) {
    avatarStyle = {
      backgroundSize: `${avatarURI ? 'cover' : 'contain'}`,
      backgroundImage: `url(${avatarURI})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    };
  }

  return (
    <div className={classNames('profile-item', className)}>
      <div
        className={'profile-item_containerVisual'}
        onClick={() => onClickHandler()}
      >
        <div
          className={`profile-item_icon ${
            !avatarURI && 'profile-item_iconfallback'
          }`}
          style={avatarStyle}
        ></div>
        <div className="profile-item_name">{name}</div>
      </div>
      {isAdminProfile(maxRatingProfileSelected) && enableProfileOperations && (
        <div className="profile-item_operations">
          <div className={'item_edit_icon'}>
            <a
              data-testid="linkEditProfile"
              className={'item_edit_icon_onClick'}
              onClick={() => handleOnEditProfile(profileData?.id)}
            >
              <i className="bi bi-pencil"></i>
            </a>
          </div>
          {validateProfileOperations(
            profileCollection,
            profileData.max_rating,
          ) && (
            <div className={'item_delete_icon'}>
              <a
                className={'item_delete_icon_onClick'}
                onClick={() => handleConfirmDeleteProfile(profileData?.id)}
              >
                <i className="bi bi-trash"></i>
              </a>
            </div>
          )}
        </div>
      )}

      {hasPIN &&
        !enableProfileOperations &&
        profileData.max_rating < MAX_RATING_DEFAULT.ADULT && (
          <div className="profile-item_lock_operation">
            <div className={'item_lock_icon'}>
              <a className={'item_lock_icon_onClick'}>
                <i className="bi bi-lock"></i>
              </a>
            </div>
          </div>
        )}
    </div>
  );
}

ProfileItem.propTypes = {
  avatarURI: PropTypes.string,
  name: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func,
  handleOnEditProfile: PropTypes.func,
  handleOnDeleteProfile: PropTypes.func,
  enableProfileOperations: PropTypes.bool,
  currentDataProfileSelected: PropTypes.object,
  profileData: PropTypes.shape({
    clientId: PropTypes.string,
    hasPIN: PropTypes.bool,
    id: PropTypes.string.isRequired,
    isMain: PropTypes.bool,
    max_rating: PropTypes.string,
    name: PropTypes.string.isRequired,
    props: PropTypes.object,
    userId: PropTypes.string,
  }).isRequired,
};

export default ProfileItem;
