export class ReducerRegistry {
  constructor() {
    this._emitChange = null;
    this._reducers = new Map();
  }

  getReducers() {
    return this._reducers;
  }

  register(name, reducer) {
    this._reducers.set(name, reducer);
    if (this._emitChange) {
      this._emitChange(this.getReducers());
    }
  }

  replaceAll(reducers = new Map()) {
    this._reducers = reducers;

    if (this._emitChange) {
      this._emitChange(this.getReducers());
    }
  }

  setChangeListener(listener) {
    this._emitChange = listener;
  }
}

const reducerRegistry = new ReducerRegistry();
export default reducerRegistry;
