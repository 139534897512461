import React from 'react';
import useWidgetTranslation from '../../customHooks/useWidgetTranslation';
import './styles.scss';

// #region /////// TBX WIDGETS ///////
import { Imgcomponent } from '@tbx/experience-widgets-lib';
import { getStoreUrlByPlataform } from '../../utils/appSettingUtils';
import PLATFORM_INFO from '../../constants/platforms';
// #endregion

function AppStore({ mobileAppUrls }) {
  const hrefAppStore =
    mobileAppUrls &&
    getStoreUrlByPlataform(mobileAppUrls, PLATFORM_INFO.ios.name);
  const hrefgooglePlay =
    mobileAppUrls &&
    getStoreUrlByPlataform(mobileAppUrls, PLATFORM_INFO.android.name);

  const translation = useWidgetTranslation([
    'Premium channels and access to thousands of hours of On Demand content.',
    'Download',
    'on your devices',
    'Brand',
    'Take all our content with you and enjoy it through our application for Android and Apple devices.',
  ]);

  return (
    <React.Fragment>
      <div className={'tbxAppStore'}>
        <Imgcomponent src="/images/logo.png" className={'activateCodeImg'} />

        <div className={'tbxAppStore_title'}>
          {
            translation[
              'premium_channels_and_access_to_thousands_of_hours_of_on_demand_content.'
            ]
          }
        </div>

        <div className="tbxAppStore_card">
          <div
            className={'tbxAppStore_card_title'}
          >{`${translation.download} ${translation.brand} ${translation.on_your_devices}`}</div>
          <div className={'tbxAppStore_card_description'}>
            {
              translation[
                'take_all_our_content_with_you_and_enjoy_it_through_our_application_for_android_and_apple_devices.'
              ]
            }
          </div>
          <div className="tbxAppStore_card_appStoreList">
            <a target="_blank" href={hrefAppStore} rel="noreferrer">
              <Imgcomponent
                className="tbxAppStore_card_appStoreList_app"
                src={'/images/ios_store.png'}
                loading={'eager'}
              />
            </a>
            <a target="_blank" href={hrefgooglePlay} rel="noreferrer">
              <Imgcomponent
                className="tbxAppStore_card_appStoreList_google"
                src={'/images/android_store.png'}
                loading={'eager'}
              />
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AppStore;
