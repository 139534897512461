import { unityContentsService } from '@tbx/experience-widgets-lib';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { convertPath } from '../../../utils/pathRoutesUtils';
import * as actions from './actions';
import { types } from './constants';

function* fetchContentWidget(action) {
  const { accessToken, contentID } = action;

  try {
    const { result } = yield call(
      unityContentsService.getContentsById,
      accessToken,
      contentID,
    );

    if (!result || result.error) {
      throw new Error(result.error);
    }

    return yield all([put(actions.loadWidgetSuccess(result))]);
  } catch (e) {
    console.error('SECTION_WIDGET_FETCH_ERROR: ', e);
    yield put(actions.loadWidgetError(e));
  }
}

function* startNextEpisode(action) {
  const { status, router, contentid, pathPlayer } = action;

  try {
    yield put(actions.startNextEpisodeSuccess(status));

    router.push(convertPath(pathPlayer, contentid));
  } catch (e) {
    console.error('SECTION_WIDGET_FETCH_ERROR: ', e);
    yield put(actions.startNextEpisodeError(e));
  }
}

function* saga() {
  yield takeLatest(types.WIDGET_FETCH, fetchContentWidget);
  yield takeLatest(types.START_NEXT_EPISODE, startNextEpisode);
}

export default saga;
