import { unityContentsService } from '@tbx/experience-widgets-lib';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { getContentListSuggestions } from '../../../utils/cloudpassUtilis';
import { decodeToken } from '../../../utils/jwtUtils';
import {
  loadMoreContents,
  searchContentsFailure,
  searchContentsSuccess,
} from './actions';
import { types } from './constants';
import * as selectors from './selectors';
import useClientConfigs from '../../../customHooks/useClientConfigs';

function* getContents(tokenData, filters) {
  const userInput = yield select((state) => selectors.selectUserInput(state));
  const { searchRecommendedContentListId } = yield select((state) =>
    selectors.selectAppSettings(state),
  );
  const tokenInfo = decodeToken(tokenData.access_token);
  const { isSuggestion } = filters;

  const contentListSuggestions =
    searchRecommendedContentListId ||
    getContentListSuggestions(tokenInfo.client);

  if ((!userInput || isSuggestion) && contentListSuggestions) {
    filters = { contentListID: contentListSuggestions, page: filters.page };
  }

  const { result } = yield call(unityContentsService.getContents, tokenData, {
    ...filters,
  });

  if (!result || result.error) {
    throw new Error(result.error);
  }

  return result;
}

function* contentsSuccessOrLoadMore(result, page, recommended, emptyResult) {
  if (page && page > 1) {
    yield put(loadMoreContents(result));
  } else {
    yield put(searchContentsSuccess(result, recommended, emptyResult));
  }
}

function* searchForContent(action) {
  const { tokenData, filters } = action;
  const { searchFilters } = useClientConfigs();

  try {
    const result = yield getContents(tokenData, filters);

    if (!result.count) {
      const resultFinal = yield getContents(tokenData, {
        ...searchFilters,
        text: '',
        page: filters.page,
        isSuggestion: true,
      });
      yield contentsSuccessOrLoadMore(resultFinal, filters.page, true, true);
    } else {
      yield contentsSuccessOrLoadMore(
        result,
        filters.page,
        filters.text === '',
        false,
      );
    }
  } catch (e) {
    console.error('[searchForContentSaga] Error: ', e);
    put(searchContentsFailure(e));
  }
}

function* saga() {
  yield takeLatest(types.SEARCH_CONTENTS, searchForContent);
}

export default saga;
