import { types } from './constants';

export const loadWidget = (accessToken, contentID) => ({
  type: types.WIDGET_FETCH,
  accessToken,
  contentID,
});

export const loadWidgetError = (error) => ({
  type: types.WIDGET_FETCH_FAILURE,
  error,
});

export const loadWidgetSuccess = (content) => ({
  type: types.WIDGET_FETCH_SUCCESS,
  content,
});

export const selectContent = (content) => ({
  type: types.CONTENT_SELECTED,
  content,
});

export const clearStates = () => ({
  type: types.CLEAR_STATES,
});

export const startNextEpisode = (status, router, contentid, pathPlayer) => ({
  type: types.START_NEXT_EPISODE,
  status,
  router,
  contentid,
  pathPlayer,
});

export const startNextEpisodeSuccess = (status) => ({
  type: types.START_NEXT_EPISODE_SUCCESS,
  status,
});

export const startNextEpisodeError = (error) => ({
  type: types.START_NEXT_EPISODE_FAILURE,
  error,
});

export const clearIsNextEpisodePlayer = () => ({
  type: types.CLEAR_IS_NEXT_EPISODE_PLAYER,
});
